import React, { useMemo } from 'react';
import { AiFillCheckCircle, AiFillExclamationCircle, AiOutlineArrowRight } from 'react-icons/ai';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import { formatDollars, formatPercent } from 'components/tables';
import { useAccountsData, useAuth } from 'contexts';

interface InsightCardProps {
  type: 'cash' | 'allocation' | 'performance' | 'fees' | 'growth';
  health: number;
  impact?: 'low' | 'medium' | 'high';
  amount?: number;
  percent?: number;
  isPending?: boolean;
}

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1.5rem',
    width: '50%',
    padding: '2rem 1rem 1rem 1rem',
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  colorIndicator: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '0.5rem',
    borderRadius: '1rem 1rem 0 0'
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    margin: '0',
    padding: '0 0.5rem',
    height: '2rem'
  },
  healthIcon: {
    marginTop: '0.2rem'
  },
  impact: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '0.25rem',
    backgroundColor: 'rgb(191,225,235)',
    marginTop: '0.5rem',
    padding: '0.25rem 0.75rem',
    fontWeight: 500,
    fontSize: '0.8rem'
  },
  amount: {
    fontSize: '1.5rem',
    fontWeight: 'bold'
  },
  details: {
    width: '100%',
    margin: '0',
    padding: '0 12%',
    fontSize: '0.9rem'
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    margin: '0',
    padding: '0 1rem 0.5rem 1rem',
    fontWeight: 500,
    height: '30%'
  },
  feeContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 2rem'
  },
  rightCol: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '150px'
  },
  matchLink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.5rem',
    color: 'white',
    fontWeight: 500,
    width: '18rem',
    padding: '0.8rem 1rem',
    marginLeft: '5px',
    borderStyle: 'solid',
    backgroundColor: 'rgb(21, 120, 246)',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(21, 120, 246, 0.75)'
    }
  }
}));

export const InsightCard = ({ type, health, impact, amount, percent, isPending }: InsightCardProps) => {
  const classes = useStyles();
  const { investorUserData } = useAccountsData();
  const { noAdvisor } = useAuth();

  const healthIcon = useMemo(() => {
    if (!health) return '';
    if (health < 80) {
      return <AiFillExclamationCircle color="#F94F58" size={35} />;
    } else if (health < 90) {
      return <AiFillExclamationCircle color="#FFC200" size={35} />;
    } else {
      return <AiFillCheckCircle color="#1FC277" size={35} />;
    }
  }, [health]);

  const healthToInitialColor = useMemo(() => {
    if (!health) return '#888888';
    if (health < 80) {
      return '#F94F58';
    } else if (health < 90) {
      return '#FFC200';
    } else {
      return '#1FC277';
    }
  }, [health]);

  const typeToTitle = useMemo(() => {
    if (type === 'cash') {
      return 'cash optimization';
    } else if (type === 'fees') {
      return 'overall fees';
    } else if (type === 'allocation') {
      return 'allocation';
    } else {
      return 'portfolio';
    }
  }, [type]);

  const healthToOptimization = useMemo(() => {
    if (!health) return '';
    if (health < 80) {
      if (type === 'cash') {
        return ' is poor.';
      } else if (type === 'fees') {
        return ' are too high.';
      } else if (type === 'allocation') {
        return ' does not match your desired risk tolerance.';
      } else if (type === 'performance' || type === 'growth') {
        return ' is performing below the benchmark index.';
      }
    } else if (health < 90) {
      if (type === 'cash') {
        return ' is great.';
      } else if (type === 'fees') {
        return ' are average.';
      } else if (type === 'allocation') {
        return ' is close to your desired risk tolerance.';
      } else if (type === 'performance' || type === 'growth') {
        return ' is close to the benchmark index.';
      }
    } else {
      if (type === 'cash') {
        return ' is great!';
      } else if (type === 'fees') {
        return ' are low!';
      } else if (type === 'allocation') {
        return ' matches your desired risk tolerance.';
      } else if (type === 'performance') {
        return ' is in line with the benchmark index.';
      } else if (type === 'growth') {
        return ' exceeds the benchmark index.';
      }
    }
  }, [health, type]);

  const content = useMemo(() => {
    if (!health) {
      if (type === 'cash') {
        return 'Connect your brokerage account to analyze your cash optimization.';
      }
      if (type === 'fees') {
        return 'Connect your brokerage account to analyze your fees.';
      }
      if (type === 'growth') {
        return 'Connect your brokerage account to analyze your portfolio growth.';
      }
      if (type === 'allocation') {
        return 'Connect your brokerage account to analyze your allocation.';
      }
    }

    if (!investorUserData?.stats_breakdown) return '';
    const investorData = investorUserData.stats_breakdown;
    const riskTolerance = investorUserData.provided_risk_tolerance;
    if (type === 'cash') {
      if (investorData.allocation_percentages.cash <= 5) {
        return 'Account currently has less than 5% allocated to cash. Current high yield savings rates and money market funds are yielding over 4.5%.';
      }
      const good_cash_percent = (investorData.cash.good / investorData.cash.total) * 100;
      if (good_cash_percent >= 95) {
        if (investorData.cash.good_yield) {
          return `Account currently has ${formatDollars(
            investorData.allocation_values.cash,
            true
          )} allocated to cash. ${formatPercent(good_cash_percent)} of your cash is currently earning around ${
            investorData.cash.good_yield
          } yield. Current high yield savings rates and money market funds are yielding over 4.5%.`;
        } else {
          return `Account currently has ${formatDollars(
            investorData.allocation_values.cash,
            true
          )} allocated to cash. ${formatPercent(
            good_cash_percent
          )} of your cash is earning a good yield. Current high yield savings rates and money market funds are yielding over 4.5%.`;
        }
      } else {
        const bad_cash_percent = ((investorData.cash.total - investorData.cash.good) / investorData.cash.total) * 100;
        return `Account currently has ${formatDollars(
          investorData.allocation_values.cash,
          true
        )} allocated to cash. ${formatPercent(
          bad_cash_percent
        )} of your cash is most likely not earning an optimal yield. Current high yield savings rates and money market funds are yielding over 4.5%.`;
      }
    }
    if (type === 'fees') {
      let feeState = 'in line with';
      if (health < 80) {
        feeState = 'much higher than';
      } else if (health < 90) {
        feeState = 'higher than';
      }
      return (
        <p style={{ fontSize: 'larger' }}>
          Your overall fee of{' '}
          <b>
            {formatPercent(investorData.total_fee_percent)} ({formatDollars(investorData.total_fees, true)})
          </b>{' '}
          is {feeState} Rainbook's benchmark for your account size. Overall fees include your AUM fee plus any fees
          associated with mutual funds and ETFs held in your portfolio.
        </p>
      );
    }
    if (type === 'growth') {
      let growthState = 'exceeds';
      if (health < 80) {
        growthState = 'is currently lagging';
      } else if (health < 90) {
        growthState = 'is in-line with';
      }
      return `Your portfolio ${growthState} the benchmark.`;
    }
    if (type === 'allocation') {
      if (!riskTolerance) {
        return 'Please provide your desired risk tolerance to analyze your asset allocation.';
      } else if (health < 80) {
        return `You indicated a ${riskTolerance} risk tolerance whereby your portfolio is not in line with the Morningstar ${riskTolerance} target allocation ${formatPercent(
          investorData.allocation_benchmark_equity
        )} Stocks and ${formatPercent(100 - investorData.allocation_benchmark_equity)} Bonds.`;
      } else if (health < 90) {
        const aggressiveness =
          investorData.allocation_percentages.equity > investorData.allocation_benchmark_equity ? 'more' : 'less';
        return `You indicated a ${riskTolerance} risk tolerance and your portfolio is ${aggressiveness} aggressive than the Morningstar ${riskTolerance} target allocation, ${formatPercent(
          investorData.allocation_benchmark_equity
        )} Stocks and ${formatPercent(100 - investorData.allocation_benchmark_equity)} Bonds.`;
      } else {
        return `You indicated a ${riskTolerance} risk tolerance and your portfolio closely aligns with the Morningstar ${riskTolerance} target allocation, ${formatPercent(
          investorData.allocation_benchmark_equity
        )} Stocks and ${formatPercent(100 - investorData.allocation_benchmark_equity)} Bonds.`;
      }
    }
  }, [health, type, investorUserData]);

  const footerText = useMemo(() => {
    if (type === 'cash') {
      return 'Speak with your advisor about optimizing your Cash.';
    } else if (type === 'fees') {
      if (health < 80) {
        const feePotential = investorUserData?.stats_breakdown?.fee_potential;
        if (feePotential && Math.floor(feePotential / 500) * 500) {
          return `You could be avoiding more than ${formatDollars(
            Math.floor(feePotential / 500) * 500
          )} in fees over the next five years while receiving comprehensive financial planning services.  Receive a no-obligation consultation today.`;
        } else {
          return 'Based on our independent analysis, you are paying too much in fees and and should speak with one of our advisors to explore how you can pay less. We have matched you with two of our vetted advisors.';
        }
      } else if (health < 90) {
        return 'You could be paying less in fees while receiving superior service from your advisor. Schedule a no obligation call today.';
      }
      return 'Understanding all the fees you pay is critical.';
    } else if (type === 'allocation') {
      return `Speak with ${noAdvisor ? 'an' : 'your'} advisor to confirm your asset allocation.`;
    }
  }, [type, investorUserData, health, noAdvisor]);

  let linkTo = undefined;
  switch (type) {
    case 'fees':
      linkTo = '/advisor-analyzer/advisor-match';
      break;
    case 'allocation':
      break;
    case 'cash':
      break;
    case 'growth':
      break;
    case 'performance':
      linkTo = '/advisor-analyzer/performance-factor';
      break;
  }

  const insight = (
    <>
      <div className={classes.colorIndicator} style={{ backgroundColor: healthToInitialColor }} />
      <div className={classes.mainContent}>
        <h3 className={classes.title}>
          <span className={classes.healthIcon}>{healthIcon}</span>
          <div>
            Your {typeToTitle} {healthToOptimization}
          </div>
        </h3>
        {impact && <div className={classes.impact}>{impact.toUpperCase()} IMPACT</div>}
        <div className={classes.amount}>{amount ? formatDollars(amount) : formatPercent(percent)}</div>
        <p className={classes.details}>{isPending ? 'Processing...' : content}</p>
      </div>
      {!noAdvisor && footerText && (
        <div className={classes.footer}>
          {type === 'fees' ? (
            <div className={classes.feeContainer}>
              <p>{footerText}</p>
              <div className={classes.rightCol}>
                <div className={classes.matchLink}>
                  Learn More
                  <AiOutlineArrowRight size={16} />
                </div>
              </div>
            </div>
          ) : (
            health < 80 && (
              <>
                <span className={classes.healthIcon}>
                  {<BsFillArrowRightCircleFill size={30} color="rgb(21, 120, 246)" />}
                </span>
                <p>{footerText}</p>
              </>
            )
          )}
        </div>
      )}
    </>
  );

  return linkTo ? (
    <Link to={linkTo} className={classes.card} style={{ color: 'inherit', textDecoration: 'none' }}>
      {insight}
    </Link>
  ) : noAdvisor && type === 'allocation' ? (
    <div className={classes.card}>
      <div className={classes.colorIndicator} style={{ backgroundColor: '#888888' }} />
      <div className={classes.mainContent}>
        <h3 className={classes.title}>
          <div>Investment Advisor vs Broker</div>
        </h3>
        <p className={classes.details}>
          <b>Investment Advisor: </b>
          <ul style={{ marginTop: '0.5em' }}>
            <li>Fiduciary: Legally bound to act in your best interests ALL the time.</li>
            <li>No commissions, period.</li>
            <li>Few, if any, conflicts.</li>
          </ul>
          <b>Broker: </b>
          <ul style={{ marginTop: '0.5em' }}>
            <li>Not a fiduciary. Held to a lesser standard.</li>
            <li>Allowed to receive compensation from products that might not be in your best interest.</li>
            <li>Conflicted.</li>
          </ul>
        </p>
      </div>
    </div>
  ) : (
    <div className={classes.card}>{insight}</div>
  );
};
