import React from 'react';
import { BsGoogle, BsMicrosoft } from 'react-icons/bs';

import { useAuth } from 'contexts/AuthContext';

import './LogIn.css';

const env =
  process.env.NODE_ENV === 'development' ? 'local.' : process.env.REACT_APP_ENV === 'development' ? 'dev.' : '';
const appUrl = `https://${env}app.rainbook.com`;

const SsoButtons = ({ isIframe, setErrors, isSignUp }: { isIframe?: boolean; setErrors?: any; isSignUp?: boolean }) => {
  const { signInWithRedirect, signInWithPopup } = useAuth();

  const ssoClick = (e: React.MouseEvent, provider: string) => {
    e.preventDefault();
    if (!isIframe) {
      signInWithRedirect(provider);
    } else {
      signInWithPopup(provider)
        .then((result: any) => {
          if (result && result.user) {
            if (window.top) {
              window.top.location.href = appUrl;
            }
          }
        })
        .catch((e: any) => {
          console.error('PopupResult: ', e.message);
          setErrors({ sso: e.message });
        });
    }
  };

  const inOrUp = isSignUp ? 'Sign up using' : 'Sign in with';

  return (
    <div>
      <button className="log-in-form-input-btn sso" onClick={(e) => ssoClick(e, 'google.com')}>
        <div>
          <BsGoogle />
          <span>{inOrUp} Google</span>{' '}
        </div>
      </button>
      <button className="log-in-form-input-btn sso" onClick={(e) => ssoClick(e, 'microsoft.com')}>
        <div>
          <BsMicrosoft />
          <span>{inOrUp} Microsoft</span>
        </div>
      </button>
    </div>
  );
};

export default SsoButtons;
