import React, { useState } from 'react';

import { Dropdown } from 'primereact/dropdown';

function DateRangeDropdown(props) {
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const date = [
    { label: 'Last 30 Days', value: 'Last 30 Days' },
    { label: 'Last Month', value: 'Last Month' },
    { label: 'Last 3 Months', value: 'Last 3 Months' }
  ];

  const onDateChange = (e) => {
    setSelectedDateRange(e.value);
  };

  return (
    <div className="dropdown-demo">
      <div className="card">
        <Dropdown
          appendTo="self"
          style={props.styles}
          value={selectedDateRange}
          options={date}
          onChange={onDateChange}
          optionLabel="label"
          placeholder={'Sort By'}
        />
      </div>
    </div>
  );
}

export default DateRangeDropdown;
