import React from 'react';

import { formatDollars, formatPercent } from 'components';

export const feeText = {
  aum_fees_higher: 'Your Advisory Fees are higher than you thought!',
  high_fee_amount: (total_fee_percent: number, total_fees: number) => (
    <>
      Your overall fee of
      <b> {formatPercent(total_fee_percent)}</b>, <b> ({formatDollars(total_fees, true)}) </b> annualy, is greater than
      Rainbook's benchmark for your account size.
    </>
  ),
  high_fees:
    'Based on our independent analysis, you are paying too much in fees and should speak with a fiduciary advisor to explore how you can pay less.',
  medium_fees: 'You could be paying less in fees while receiving superior service from your advisor.',
  good: 'Rainbook did not identify any abnormal fees, but there may still be opportunity to reduce your fees.',
  potential: (fee_potential: number) => (
    <>
      You could be avoiding more than {formatDollars(Math.floor(fee_potential / 500) * 500)} in fees over the next five
      years while receiving comprehensive financial planning services.
    </>
  )
};
export const performanceText = {
  low_growth: 'Your portfolio growth is performing below the benchmark index.',
  cash_allocation: 'Your cash allocation is poor.',
  bad_cash: (total_cash: number, bad_cash_percent: number) =>
    `Account currently has ${formatDollars(total_cash, true)} allocated to cash. ${formatPercent(
      bad_cash_percent
    )} of your cash is most likely not earning an optimal yield.`
};

export const advisorText = {
  dually_registered: (advisor_first_name: string) =>
    `${advisor_first_name} is dually registered as both a Broker and as a Registered Investment Advisor.`,
  broker_only:
    'Your advisor is only registered as a Broker, not an Investment Advisor, which means they are only obligated to find suitable investments for you whereas a Registered Investment Advisor is required, at all times, to act in the best interest of you, the client.',
  not_registered:
    'Your advisor advisor is not a Registered Investment Advisor which is required, at all times, to act in the best interest of you, the client.',
  has_disclosures: (advisor_first_name: string, advisor_last_name: string) =>
    `${advisor_first_name} ${advisor_last_name} has one or more disclosures which should be reviewed.`,
  no_credentials: (advisor_first_name: string) =>
    `${advisor_first_name} does not hold one of the premier industry designations (CFP, CFA, CPA).`
};
