import React, { useCallback, useContext, useEffect, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import firebase from 'firebase/app';

import { auth, storage } from '../firebase';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const url = process.env.REACT_APP_API_URL;

async function submitUser(user, refresh = false) {
  const ID = await user.getIdToken(refresh);
  await fetch(`${url}/api/v1/users/current`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ID}`
    }
  });
}

async function getRbUser(user) {
  const ID = await user.getIdToken();
  const response = await fetch(`${url}/api/v1/users/current.json`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ID}`
    }
  });
  const json = await response.json();
  return json.data;
}

async function submitUserPhone(user, phone) {
  const ID = await user.getIdToken();
  await fetch(`${url}/api/v1/users/current`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ID}`
    },
    body: JSON.stringify({
      data: {
        id: 0, //json:api requires but we're using id from auth token
        type: 'users',
        attributes: { phone_number: phone }
      }
    })
  });
}

async function submitUserAttributes(user, attributes) {
  const ID = await user.getIdToken();
  await fetch(`${url}/api/v1/users/current`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ID}`
    },
    body: JSON.stringify({
      data: {
        id: 0, //json:api requires but we're using id from auth token
        type: 'users',
        attributes
      }
    })
  });
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [displayPhoto, setDisplayPhoto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [rbUser, setRbUser] = useState();
  const [noAdvisor, setNoAdvisor] = useState(null);

  async function sendVerificationEmail(newUser, resend = false) {
    const user = resend ? currentUser : newUser;
    const ID = await user.getIdToken(true);

    return await fetch(`${url}/api/v1/users/send_verification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      },
      body: JSON.stringify({
        email: user.email,
        env: process.env.REACT_APP_ENV
      })
    });
  }

  async function sendPasswordResetEmail(email) {
    await fetch(`${url}/api/v1/users/send_password_reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, env: process.env.REACT_APP_ENV })
    });
  }

  async function signUp(email, password) {
    const user = await auth.createUserWithEmailAndPassword(email, password);
    return user;
  }

  function signInWithRedirect(providerId) {
    let provider = new firebase.auth.OAuthProvider(providerId);
    if (providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
      provider = new firebase.auth.GoogleAuthProvider();
    }
    auth.signInWithRedirect(provider).catch((e) => console.error(e.message));
  }

  function signInWithPopup(providerId) {
    let provider = new firebase.auth.OAuthProvider(providerId);
    if (providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
      provider = new firebase.auth.GoogleAuthProvider();
    }
    return auth.signInWithPopup(provider);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    setCurrentUser(null);
    return auth.signOut();
  }

  /**
   * Sets displayPhoto's initial value to whichever it finds first:
   * 1. Thumbnail in photoURL
   * 2. Requests the thumbnail url, updates the photoURL, and sets that
   * 3. Requests the original url and sets that
   */
  async function initDisplayPhoto(user) {
    if (user) {
      if (user.photoURL) {
        //console.log(`photoURL: ${user.photoURL}`);
        setDisplayPhoto(user.photoURL);
        return;
      }

      const thumbURL = await storage
        .ref(`profiles/${user.uid}/avatar/resize`)
        .child('image_300x300.png')
        .getDownloadURL()
        .catch((err) => {
          //console.error(err);
        });

      if (thumbURL) {
        //console.log(`thumbURL: ${thumbURL}`);
        await user
          .updateProfile({
            photoURL: thumbURL
          })
          .catch(function (error) {
            console.error(error);
          });
        await user.getIdToken(true).catch((error) => {
          console.error(error);
        });
        await submitUser(user).catch((error) => {
          console.error(error);
        });
        setDisplayPhoto(thumbURL);
        return;
      }

      // Have to list and pull the first result
      // since we don't know the original's extension
      const list = await storage.ref(`profiles/${user.uid}/avatar`).list({ maxResults: 1 });
      if (list.items.length > 0) {
        const origURL = await list.items[0].getDownloadURL().catch((err) => {
          console.error(err);
        });
        if (origURL) {
          //console.log(`origURL: ${origURL}`);
          setDisplayPhoto(origURL);
          return;
        }
      }
      return null;
    } else {
      console.error('Could not initialize display photo because user was null');
      console.error(user);
      return null;
    }
  }

  async function displayLocalPreview(preview) {
    /**
     * Empty the photoURL
     */
    await currentUser
      .updateProfile({
        photoURL: null
      })
      .catch(function (error) {
        console.error(error);
      });
    await currentUser.getIdToken(true).catch((error) => {
      console.error(error);
    });
    await submitUser(currentUser).catch((error) => {
      console.error(error);
    });
    /**
     * Set local file as avatar image for the remainder of the session
     * (no need to pull from server)
     */
    setDisplayPhoto(preview);
  }

  const extExp = /(?:\.([^.]+))?$/;

  /**
   * Updates the users profile photo.
   * This will only upload original
   * @file the reference to the selected file
   */
  function updatePhoto(file) {
    if (file) {
      const match = extExp.exec(file.name);
      if (match && match.length > 1 && match[1]) {
        //console.log(`ext: ${match[1]}`);
        const uploadTask = storage.ref(`profiles/${currentUser.uid}/avatar/image.${match[1]}`).put(file);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            //const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            //console.log(`progress: ${progress}`);
          },
          (error) => {
            console.log(error);
          },
          () => {
            //console.log("image uploaded!");
            //console.log(e.target.files[0]);
            var reader = new FileReader();

            reader.onload = function (ev) {
              displayLocalPreview(ev.target.result);
            };
            reader.readAsDataURL(file);
          }
        );
      }
    }
  }

  function updateUser(values) {
    if (currentUser) {
      let updates = {};
      if (values.name) {
        updates['displayName'] = values.name;
      }
      //if(values.phoneNumber){ updates['phoneNumber'] = values.phoneNumber }
      currentUser
        .updateProfile(updates)
        .then(function () {
          if (values.email && currentUser.email !== values.email) {
            return currentUser.verifyBeforeUpdateEmail(values.email);
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(() => {
          //force token refresh to use new name
          submitUser(currentUser, true).catch((error) => {
            console.error(error);
          });
        });
    }
  }

  async function reloadUser(values) {
    if (currentUser) {
      await currentUser.reload().catch(function (error) {
        console.error(error);
      });
      if (auth.currentUser.emailVerified) {
        await submitUser(auth.currentUser, true).catch((error) => {
          console.error(error);
        });
        setCurrentUser(auth.currentUser);
        return true;
      }
      return false;
    }
  }

  function updateUserPhone(phone) {
    if (currentUser) {
      submitUserPhone(currentUser, phone).catch((error) => {
        console.error(error);
      });
    }
  }

  function updateUserAttributes(attributes) {
    if (currentUser) {
      submitUserAttributes(currentUser, attributes).catch((error) => {
        console.error(error);
      });
    }
  }

  const getMstarAuth = useCallback(async () => {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/baa`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      }
    });

    const json = await response.json();
    if (!json.data) return console.error('No data found');

    const { session_id, csrf_token } = json.data.attributes;

    return {
      jsessionId: session_id,
      csrfToken: csrf_token
      // personId: person_id
    };
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('user.uid', user.uid);
        }
        submitUser(user).catch((error) => {
          console.error(error);
        });
        initDisplayPhoto(user);
        datadogRum.setUser({
          id: user.uid
        });
        if (window.olark) {
          window.olark('api.visitor.updateEmailAddress', {
            emailAddress: user.email
          });
          window.olark('api.visitor.updateFullName', {
            fullName: user.displayName
          });
        }
        getRbUser(user).then((rbUser) => {
          if (rbUser) {
            setRbUser(rbUser);
            setNoAdvisor(rbUser.provided_no_advisor);
          }
        });
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    rbUser,
    signUp,
    signInWithRedirect,
    signInWithPopup,
    login,
    logout,
    sendVerificationEmail,
    sendPasswordResetEmail,
    updateUser,
    reloadUser,
    displayPhoto,
    updatePhoto,
    updateUserPhone,
    updateUserAttributes,
    getRbUser,
    getMstarAuth,
    noAdvisor,
    setNoAdvisor
  };

  return <AuthContext.Provider value={value}>{!loading && <>{children}</>}</AuthContext.Provider>;
}
