import React, { useEffect, useState } from 'react';
import { IoMailOpenOutline } from 'react-icons/io5';

import { CircularProgress } from '@material-ui/core';

import { useAuth } from 'contexts/AuthContext';
import { useInterval } from 'utils';

function VerifyEmailPopup({ newAccount, okClick }) {
  const { sendVerificationEmail, currentUser, reloadUser } = useAuth();
  const [errorMsg, setErrorMsg] = useState();
  const [loading, setLoading] = useState(true);
  const [retry, setRetry] = useState(false);
  const [retried, setRetried] = useState(false);

  const sendClick = async () => {
    const resp = await sendVerificationEmail({}, true);
    if (resp.ok) {
      setErrorMsg(undefined);
      setLoading(true);
      setRetried(true);
    } else {
      setErrorMsg('Error sending. Please wait a minute and try again.');
    }
  };

  const neverGotIt = () => {
    setRetry(true);
  };

  const emailVerified = currentUser?.emailVerified;

  useInterval(
    () => {
      reloadUser().then((verified) => {
        if (verified) {
          setLoading(false);
        }
      });
    },
    emailVerified ? undefined : 2000
  );

  return (
    <div className="verify-email-popup-container">
      <div className="verify-email-popup">
        <div className="verify-email-body">
          <div className="verify-email-header">Verify your email</div>
          <IoMailOpenOutline className="verify-email-icon" />
          <div className="verify-email-mid">
            To continue, <b>click the link in your email</b> to secure and verify your {newAccount && 'new'} account.
          </div>
          {!loading && (
            <button
              className="confirm-email-button"
              style={{ opacity: loading ? '0.8' : '1' }}
              disabled={loading}
              onClick={okClick}
            >
              Continue
            </button>
          )}
          <p className="log-in-error-msg">{errorMsg}</p>
          {loading &&
            (!retry ? (
              <div className="verify-email-footer" onClick={neverGotIt}>
                Can't find the email?
              </div>
            ) : !retried ? (
              <>
                <p>Check your spam folder or click resend to retry sending a new verification link.</p>
                <div className="verify-email-footer" onClick={sendClick}>
                  Resend email
                </div>
              </>
            ) : (
              <div>Email resent.</div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default VerifyEmailPopup;
