import React, { useCallback, useState } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';

import { CircularProgress, Dialog, DialogContent, makeStyles } from '@material-ui/core';
import { Widget } from '@typeform/embed-react';

import DialogTitleX from 'components/dialogs/DialogTitleX';
import { useAccountsData, useAuth } from 'contexts';

interface TypeformProps {
  score_attribute: string;
  typeformId: string;
}

export interface Step {
  title: string;
  isComplete: boolean;
  isPending?: boolean;
  actionText: string;
  action: string | (() => void);
  score_attribute?: string;
  subSteps?: Step[];
}

interface NewAccountStepsProps {
  header?: string;
  steps: Step[];
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    width: 700,
    [theme.breakpoints.down('sm')]: {
      width: 400,
      height: 'fit-content'
    }
  },
  title: {
    margin: 0,
    width: '100%',
    justifyContent: 'flex-start',
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      textAlign: 'center'
    }
  },
  stepsContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '0.5rem',
    width: '100%',
    padding: '1.5rem',
    borderRadius: '0.5rem',
    boxShadow: '0 0 0.5rem rgba(0,0,0,0.25)',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      gap: '0'
    }
  },
  step: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    fontSize: '1.25rem',
    '& h3': {
      margin: 0
    }
  },
  mainStep: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '1rem',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      gap: '5px'
    }
  },
  subStepList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    padding: '0 0 0 6rem',
    margin: '0',
    fontSize: '1rem',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      padding: '0'
    }
  },
  stepTitle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  stepActionTitle: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline'
    }
  },
  subStep: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '2rem',
    gap: '1rem',
    fontStyle: 'italic',
    fontSize: '1rem'
  },
  button: {
    color: '#ffffff',
    backgroundColor: 'rgb(21, 120, 246)',
    borderRadius: '2rem',
    width: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    fontSize: '1rem',
    fontWeight: 600,
    padding: '0.5rem 1rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem'
    },
    '&:hover': {
      backgroundColor: 'rgba(21, 120, 246, 0.75)',
      transition: 'all 0.5s ease'
    }
  },
  gray: {
    color: '#888888'
  }
}));

export const NewAccountSteps = ({ header, steps }: NewAccountStepsProps) => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { investorUserData, setScoreSubmitted, scoreSubmitted } = useAccountsData();
  const { advisor } = investorUserData;

  const [scoreToSubmit, setScoreToSubmit] = useState<TypeformProps | null>(null);

  const closeTypeform = () => {
    setScoreToSubmit(null);
  };

  const submittedTypeform = useCallback(() => {
    setScoreSubmitted((scoreSubmitted: string[]) => [...scoreSubmitted, scoreToSubmit?.score_attribute]);
    setScoreToSubmit(null);
  }, [scoreToSubmit, setScoreSubmitted]);

  const handleStepAction = useCallback(
    (step: Step) => {
      const { score_attribute, action } = step;
      if (score_attribute && typeof action === 'string') {
        setScoreToSubmit({
          score_attribute,
          typeformId: action
        });
      } else if (typeof action === 'function') {
        action();
      }
    },
    [setScoreToSubmit]
  );

  return (
    <>
      <div className={classes.container}>
        {!!header && <h1 className={classes.title}>{header}</h1>}
        <div className={classes.stepsContainer}>
          {steps.map((step, index) => (
            <div className={classes.step} key={index}>
              <div className={classes.mainStep}>
                <div>
                  {step.isComplete ? (
                    <AiFillCheckCircle size={35} color="#1FC277" />
                  ) : (
                    <AiFillCheckCircle size={35} color="#EEEEEE" />
                  )}
                </div>
                <h3>
                  <span className={`${(step.isComplete || step.isPending) && classes.gray}`}>
                    {steps.length > 1 ? `${index + 1}.` : ''}{' '}
                    <span className={step.subSteps || step.isComplete || step.isPending ? '' : classes.stepTitle}>
                      {step.title}
                    </span>
                  </span>
                </h3>
                {!step.isComplete &&
                ((step.isPending !== undefined && step.isPending) ||
                  (step.score_attribute && scoreSubmitted.includes(step.score_attribute))) ? (
                  <>
                    <CircularProgress size={20} style={{ color: 'rgb(21, 120, 246)' }} /> Processing...
                  </>
                ) : (
                  !step.isComplete &&
                  step.action && (
                    <button className={classes.button} onClick={() => handleStepAction(step)}>
                      <span className={classes.stepActionTitle}>{step.title}</span>
                      <span className={classes.stepTitle}>{step.actionText}</span>
                    </button>
                  )
                )}
              </div>
              {step.subSteps && (
                <ul className={classes.subStepList}>
                  {step.subSteps.map((subStep, subIndex) => (
                    <li className={classes.subStep} key={subIndex}>
                      <span>•</span>
                      <span className={classes.stepTitle}>{subStep.title}</span>
                      {subStep.isPending ? (
                        <CircularProgress size={20} style={{ color: 'rgb(21, 120, 246)' }} />
                      ) : subStep.action ? (
                        !subStep.isComplete ? (
                          <button className={classes.button} onClick={() => handleStepAction(subStep)}>
                            <span className={`${(subStep.isComplete || subStep.isPending) && classes.gray}`}>
                              <span className={classes.stepTitle}>{subStep.actionText}</span>
                              <span className={classes.stepActionTitle}>{subStep.title}</span>
                            </span>
                          </button>
                        ) : (
                          <span className={`${(subStep.isComplete || subStep.isPending) && classes.gray}`}>
                            <span className={classes.stepActionTitle}>{subStep.title}</span>
                          </span>
                        )
                      ) : (
                        <></>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>
      <Dialog fullScreen open={!!scoreToSubmit} fullWidth={true} maxWidth={'lg'} onClose={closeTypeform}>
        <DialogTitleX onClose={closeTypeform} style={{ backgroundColor: 'rgb(0, 65, 114)' }} />
        <DialogContent style={{ backgroundColor: 'rgb(0, 65, 114)' }}>
          <Widget
            id={scoreToSubmit?.typeformId || ''}
            onSubmit={submittedTypeform}
            style={{ width: '100%', height: '100%' }}
            hidden={{
              user_id: currentUser.uid,
              email: currentUser.email,
              advisor_first_name: advisor?.first_name || 'your advisor',
              advisor_last_name: advisor?.last_name || ''
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
