import React, { useRef } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FaHandshake, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { CollapsibleText, LinkStep, MorningstarConnect, NewAccountSteps, ProcessingMessage } from 'components';
import { Step } from 'components/cards/NewAccountSteps';
import { useAccountsData, useAuth } from 'contexts';

import useStyles from './LandingStyles';

export const LandingNoAdvisor = () => {
  const classes = useStyles();
  const history = useHistory();
  const mstarRef = useRef<HTMLInputElement>(null);

  const {
    baaUserData: { baa_investor_credentials },
    investorUserData: {
      advisor,
      issue_types,
      score_performance,
      score_cash,
      score_fees,
      statement_in_progress,
      provided_location_preference
    },
    BaaAccounts,
    scoreSubmitted
  } = useAccountsData();

  const { noAdvisor, currentUser } = useAuth();

  const setupComplete = noAdvisor
    ? BaaAccounts.length > 0 && score_performance
    : BaaAccounts.length > 0 && score_performance && advisor;
  const issueTypes: [string] = issue_types || [];
  const isDataIssue = issueTypes.includes('data');
  const performancePending = scoreSubmitted.includes('score_performance') && (noAdvisor || advisor);
  const noAdvisorPending = scoreSubmitted.includes('provided_location_preference');

  const connectStep = baa_investor_credentials?.length
    ? {
        title: 'Connect Account or Upload Statements',
        isComplete: score_fees && score_cash,
        actionText: 'Fix Connection Issues',
        score_attribute: 'score_fees',
        action: () => mstarRef.current?.click()
      }
    : {
        title: 'Connect Account or Upload Statements',
        isComplete: score_fees && score_cash,
        isPending: BaaAccounts.length > 0,
        actionText: 'Connect or Upload',
        score_attribute: 'score_fees',
        action: () => history.push('/advisor-analyzer/add-account')
      };

  let noAdvisorSteps: [Step] = [
    {
      title: 'Find your Advisor Match',
      isComplete: provided_location_preference,
      actionText: 'Answer a few Questions',
      action: 'KVKEduIx',
      score_attribute: 'provided_location_preference'
    }
  ];

  const firstName = currentUser?.displayName ? currentUser.displayName.split(' ')[0] : undefined;

  return (
    <>
      <h2 className={classes.title}>Welcome to Rainbook{firstName ? `, ${firstName}` : ''}!</h2>
      <div className={classes.section}>
        <div className={classes.header}>
          <h2 className={classes.title}>
            <FaHandshake /> Connect with an Advisor on the Rainbook Network
          </h2>
        </div>
        <p>
          Rainbook partners with premier wealth management firms managing over $22 billion in assets, offering holistic
          financial planning by CFP®, CFA, and CPA-designated advisors with impeccable credentials. We work exclusively
          with independent fiduciary RIAs.
        </p>
        <div className={classes.row}>
          <CollapsibleText
            title={
              <div className={classes.collapsibleTitle}>
                <AiFillCheckCircle style={{ marginRight: '5px' }} size={35} color="#1FC277" />
                <b>Fiduciary</b>
                <span className={classes.collapsibleTitleText}>
                  : Legally bound to act in your best interest. No brokers.
                </span>
              </div>
            }
            text={
              <>
                A fiduciary is:
                <ul>
                  <li>Indpendent, always.</li>
                  <li>Provides complete transparency.</li>
                  <li>Rejects all commissions, avoids conflicts.</li>
                </ul>
                Whereas brokers:
                <ul>
                  <li>Receive direct and indirect commissions.</li>
                  <li>
                    Receive 12b-1 fee, part of those hidden fund fees you pay when owning some mutual funds or etfs.
                  </li>
                  <li>
                    Earn large commissions on insurance products, incentivizing them to promote and sell to clients.
                  </li>
                  <li>Unfortunately, 80% of investment advisors are registered brokers.</li>
                </ul>
              </>
            }
          />
        </div>
        <div className={classes.row}>
          <CollapsibleText
            title={
              <div className={classes.collapsibleTitle}>
                <AiFillCheckCircle style={{ marginRight: '5px' }} size={35} color="#1FC277" />
                <b>Certified</b>
                <span className={classes.collapsibleTitleText}>: Designated CFP®, CFA or CPA.</span>
              </div>
            }
            text={
              <ul className={classes.list}>
                <li>
                  Certified Financial Planner, CFP®, requires several years of delivering financial planning services in
                  addition to passing the comprehensive CFP Certification Exam. The CFP® designation is the gold
                  standard for financial advisors.
                </li>
                <li>
                  Chartered Financial Analyst, CFA, fewer than one in five candidates become a CFA charter holder, which
                  takes on average 1,000+ hours of rigorous study plus four years of professional experience.
                </li>
                <li>
                  Certified Public Accountant, CPA, are tax professionals who bring a wealth of knowledge and experience
                  through their continuing education requirements and relevant work experience.
                </li>
              </ul>
            }
          />
        </div>
        <div className={classes.row}>
          <CollapsibleText
            title={
              <div className={classes.collapsibleTitle}>
                <AiFillCheckCircle style={{ marginRight: '5px' }} size={35} color="#1FC277" />
                <b>Fee-only</b>
                <span className={classes.collapsibleTitleText}>: Competitive pricing. No hidden fees.</span>
              </div>
            }
            text={
              <ul className={classes.list}>
                <li>
                  Fee only means management fee <i>only</i>. The advisor only receives the management fee you and the
                  advisor agreed to.
                </li>

                <li>
                  Many other advisors are dual registered and are fee <i>based</i>. This allows them to charge
                  commissions on top of their management fee.
                </li>

                <li>
                  Commissions can range from selling insurance to selling certain investment products, such as mutual
                  funds. Mutual funds literally pay those advisors and/or firms a 12b-1 fee for placing their investors
                  money in their fund. Conflict of interest!
                </li>
              </ul>
            }
          />
        </div>
        {noAdvisorPending ? (
          <div className={classes.row}>
            <ProcessingMessage />
          </div>
        ) : provided_location_preference ? (
          <>
            <div className={classes.row}>
              <p>
                Based on your information provided, we have matched you with <b>two premier fiduciary advisors</b> on
                the Rainbook Advisor Network for a <i>free</i> consultation. Rainbook members make the first move.
                Strict no spam policy.
              </p>
            </div>
            <div className={classes.match}>
              <LinkStep
                title="Schedule a free consultation"
                actionText="No Obligation - Discover Your Matches"
                to="/advisor-analyzer/advisor-match"
              />
            </div>
          </>
        ) : (
          <div className={classes.row}>
            <NewAccountSteps steps={noAdvisorSteps} />
          </div>
        )}
      </div>
      <div className={classes.section}>
        <div className={classes.header}>
          <h2 className={classes.title}>
            <FaSearch style={{ verticalAlign: 'middle' }} /> Get advice on your current portfolio.
          </h2>
        </div>
        {!advisor && (
          <div className={classes.row}>
            <ul className={classes.list}>
              <li>Rainbook will analyze your portfolio to streamline your discussion with a fiduciary advisor.</li>
              <li>
                By connecting your investment account we can deliver a snapshot of your current financial situation:
              </li>
            </ul>
          </div>
        )}
        {statement_in_progress || isDataIssue || performancePending ? (
          <div className={classes.row}>
            <ProcessingMessage {...{ statement_in_progress, isDataIssue }} />
          </div>
        ) : (
          <div className={classes.row}>
            <NewAccountSteps steps={[connectStep]} />
            <MorningstarConnect refForClick={mstarRef} />
          </div>
        )}
      </div>
    </>
  );
};
