import React, { useEffect } from 'react';
import HubspotForm from 'react-hubspot-form';

import { CircularProgress, Dialog, makeStyles } from '@material-ui/core';

import { useAccountsData } from 'contexts';

import DialogTitleX from './DialogTitleX';

interface DialogProps {
  open: boolean;
  embedded?: boolean;
  onClose?: () => void | undefined | ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void);
  onSubmit?: () => void;
}

const useStyles = makeStyles(() => ({
  supportLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
export const StatementsDialog: React.FC<DialogProps> = ({ open, onClose, onSubmit, embedded = false }) => {
  const classes = useStyles();
  const { updateInvestor, investorUserData, setScoreSubmitted } = useAccountsData();
  const investorId = investorUserData?.id;
  // hack to avoid needing jquery https://github.com/escaladesports/react-hubspot-form/issues/22
  useEffect(() => {
    const onHubspotEvent = (event: any) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        if (event.data.id === '1c7d61b7-b13a-4895-b288-6f654985bf79') {
          updateInvestor(investorId, { statement_in_progress: true });
          setScoreSubmitted((scoreSubmitted: string[]) => [
            ...scoreSubmitted,
            'score_performance',
            'score_fees',
            'score_cash'
          ]);
          if (onSubmit) {
            onSubmit();
          }
          if (onClose && embedded) {
            onClose();
          }
        }
      }
    };
    window.addEventListener('message', onHubspotEvent);
    return () => {
      window.removeEventListener('message', onHubspotEvent);
    };
  }, [updateInvestor, investorId, setScoreSubmitted, onClose, onSubmit, embedded]);
  return embedded ? (
    <HubspotForm
      portalId="9455422"
      formId="1c7d61b7-b13a-4895-b288-6f654985bf79"
      loading={
        <div className={classes.supportLoading}>
          <CircularProgress />
        </div>
      }
      inlineMessage="Got it! We'll be in contact after processing."
    />
  ) : (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth style={{ zIndex: 2000 }}>
      <DialogTitleX onClose={onClose} />
      <div style={{ padding: 20 }}>
        <HubspotForm
          portalId="9455422"
          formId="1c7d61b7-b13a-4895-b288-6f654985bf79"
          loading={
            <div className={classes.supportLoading}>
              <CircularProgress />
            </div>
          }
          inlineMessage="Got it! We'll be in contact after processing."
        />
      </div>
    </Dialog>
  );
};
