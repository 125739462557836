import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Backdrop, CircularProgress } from '@material-ui/core';
import moment from 'moment';

import { isAlternative, isExpense, isLiability, isLiquid, isPersonal } from 'pages/wealthHub';
import { useInterval } from 'utils';

import { useAuth } from './AuthContext';

const AccountsContext = React.createContext();

const url = process.env.REACT_APP_API_URL;

export function useAccountsData() {
  return useContext(AccountsContext);
}

export function formatAccounts(included, excluded, page) {
  let d = [];
  const showTopAccountsNum = 3;
  included.slice(0, showTopAccountsNum).forEach((acct) => {
    d.push({
      label: acct.name.substring(0, 18),
      value: acct.balance_current,
      assetType: page
    });
  });
  if (included.length > showTopAccountsNum || excluded.length > 0) {
    const otherInclTotal = included.slice(showTopAccountsNum).reduce((a, v) => a + v.balance_current, 0);
    const exclTotal = excluded.reduce((a, v) => a + v.balance_current, 0);
    d.push({
      label: 'Other',
      value: otherInclTotal + exclTotal,
      assetType: page
    });
  }
  return d;
}

export function shouldExcludeAcct(acct) {
  return acct.is_hidden || acct.is_closed;
}

// Returns an array of what types are contained within the account (can be multiple)
export function accountTypes(account) {
  let types = [];
  // console.log(isLiquid(account), isPersonal(account), isAlternative(account), isExpense(account), isLiability(account))
  if (isLiquid(account)) types.push('Liquid');
  if (isPersonal(account)) types.push('Personal');
  if (isAlternative(account)) types.push('Alternative');
  if (isExpense(account)) types.push('Cash Flow');
  if (isLiability(account)) types.push('Liabilities');

  return types;
}

function sortAccountsFn(positionAccountIds) {
  return function (a, b) {
    if (positionAccountIds.indexOf(a.id) === -1 && positionAccountIds.indexOf(b.id) === -1) {
      return parseFloat(b.balance_current) - parseFloat(a.balance_current);
    }
    if (positionAccountIds.indexOf(a.id) === -1) {
      return 1;
    }
    if (positionAccountIds.indexOf(b.id) === -1) {
      return -1;
    }
    return positionAccountIds.indexOf(a.id) - positionAccountIds.indexOf(b.id);
  };
}

const getInvestorData = async (ID) => {
  const userResponse = await fetch(`${url}/api/v1/investors/current.json?include=advisor`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ID}`
    }
  });

  if (userResponse.ok) {
    return (await userResponse.json()).data;
  } else {
    return undefined;
  }
};

const getBaaInvestorData = async (ID) => {
  const userResponse = await fetch(`${url}/api/v1/baa_investors/current.json?include=baa_investor_credentials`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ID}`
    }
  });

  if (userResponse.ok) {
    return (await userResponse.json()).data;
  } else {
    return undefined;
  }
};

export function AccountsProvider({ children }) {
  const [onboarding, setOnboarding] = useState(false);
  const [doneOnboarding, setDoneOnboarding] = useState(false);

  const [usingBaa, setUsingBaa] = useState(false);
  const [baaUserData, setBaaUserData] = useState({});
  const [investorUserData, setInvestorUserData] = useState({});
  const [investorTimeseriesData, setInvestorTimeseriesData] = useState();
  const [investorPerformanceData, setInvestorPerformanceData] = useState();
  const [baaBenchmarkPerformanceData, setBaaBenchmarkPerformanceData] = useState([]);
  const [baaBenchmarkName, setBaaBenchmarkName] = useState('');
  const [baaPerformanceStartDate, setBaaPerformanceStartDate] = useState();
  const [scoreSubmitted, setScoreSubmitted] = useState([]);
  const [checkInterval, setCheckInterval] = useState();

  const [BaaAccounts, setBaaAccounts] = useState([]);
  const [LiqData, setLiqData] = useState([]);
  const [LiqAccounts, setLiqAccounts] = useState([]);
  const [LiqIds, setLiqIds] = useState([]);
  const [PersData, setPersData] = useState([]);
  const [PersAccounts, setPersAccounts] = useState([]);
  const [PersIds, setPersIds] = useState([]);
  const [AltData, setAltData] = useState([]);
  const [AltAccounts, setAltAccounts] = useState([]);
  const [AltIds, setAltIds] = useState([]);
  const [AllAccounts, setAllAccounts] = useState([]);
  const [ExpenseAccounts, setExpenseAccounts] = useState([]);
  const [LiabilityData, setLiabilityData] = useState([]);
  const [LiabilityAccounts, setLiabilityAccounts] = useState([]);
  const [LiabilityIds, setLiabilityIds] = useState([]);
  const [LiabilitiesData, setLiabilitiesData] = useState(0);
  //const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [AllFeaturedInstitutions, setAllFeaturedInstitutions] = useState([]);
  const [selectableInstitutions, setSelectableInstitutions] = useState([]);
  const [CategoryDropdownData, setCategoryDropdownData] = useState([]);
  const [selectedMonth, privSetSelectedMonth] = useState({
    startStr: moment().startOf('month').format('YYYY-MM-DD'),
    endStr: moment().endOf('month').format('YYYY-MM-DD'),
    value: 0
  });
  const [CategoryData, setCategoryData] = useState([]);
  const [CategoryTotals, setCategoryTotals] = useState([]);
  const [InsightsData, setInsightsData] = useState([]);
  const [TagsData, setTagsData] = useState([]);

  const [liqChange, setLiqChange] = useState();
  const [persChange, setPersChange] = useState();
  const [altChange, setAltChange] = useState();
  const [liabilityChange, setLiabilityChange] = useState();

  const [AllIds, setAllIds] = useState([]);

  const { currentUser } = useAuth();

  const liabilities = ['credit', 'loan'];

  const advisorScoreChartData = useMemo(() => {
    const monthlyData = [];
    const filtered = investorTimeseriesData ? investorTimeseriesData.filter((d) => d.score_overall) : [];
    for (const key in filtered) {
      const date = moment(filtered[key].id, 'YYYY-MM-DD');
      const monthString = date.format("MMM 'YY");
      monthlyData.push({
        month: monthString,
        value: filtered[key].score_overall?.toFixed(2)
      });
    }
    if (monthlyData.length === 1) {
      monthlyData.unshift(monthlyData[0]);
    }
    return {
      labels: monthlyData.map((data) => data.month),
      datasets: [
        {
          label: 'Advisor Score',
          usePercent: true,
          backgroundColor: 'rgba(21, 120, 246, 0.2)',
          borderColor: 'rgb(21, 120, 246)',
          data: monthlyData.map((data) => data.value)
        }
      ]
    };
  }, [investorTimeseriesData]);

  const feesTimeseriesData = useMemo(() => {
    const monthlyData = [];
    const benchMonthlyData = [];
    const filtered = investorTimeseriesData
      ? investorTimeseriesData.filter(
          (d) => d.fee_aum_percent != null && d.fee_exr_percent != null && d.fee_txn_percent != null
        )
      : [];
    const first = filtered[0];
    const benchFiltered =
      first && investorTimeseriesData
        ? investorTimeseriesData.filter((d) => d.id >= first.id && d.fee_benchmark_percent != null)
        : [];
    for (const key in filtered) {
      const date = moment(filtered[key].id, 'YYYY-MM-DD');
      const monthString = date.format("MMM 'YY");
      monthlyData.push({
        month: monthString,
        value: (
          filtered[key].fee_aum_percent +
          filtered[key].fee_exr_percent +
          filtered[key].fee_txn_percent +
          (filtered[key].fee_fld_percent || 0)
        ).toFixed(2)
      });
    }
    for (const key in benchFiltered) {
      const date = moment(benchFiltered[key].id, 'YYYY-MM-DD');
      const monthString = date.format("MMM 'YY");
      benchMonthlyData.push({
        month: monthString,
        value: benchFiltered[key].fee_benchmark_percent?.toFixed(2)
      });
    }
    if (monthlyData.length === 1) {
      monthlyData.unshift(monthlyData[0]);
    }
    if (benchMonthlyData.length === 1) {
      benchMonthlyData.unshift(benchMonthlyData[0]);
    }
    return {
      labels: monthlyData.map((data) => data.month),
      datasets: [
        {
          label: 'Fees',
          usePercent: true,
          backgroundColor: 'rgba(21, 120, 246, 0.2)',
          borderColor: 'rgb(21, 120, 246)',
          data: monthlyData.map((data) => data.value)
        },
        {
          label: 'Rainbook Fee Benchmark',
          usePercent: true,
          backgroundColor: 'rgba(21, 120, 246, 0.2)',
          borderColor: 'rgb(200, 120, 246)',
          data: benchMonthlyData.map((data) => data.value)
        }
      ]
    };
  }, [investorTimeseriesData]);

  const portfolioPerformanceData = useMemo(() => {
    const withValues = investorPerformanceData
      ? investorPerformanceData.filter((data) => data.percent_change != null)
      : [];
    const first = withValues[0];
    const benchValues =
      first && baaBenchmarkPerformanceData
        ? baaBenchmarkPerformanceData.filter((data) => data.percent_change != null && data.id >= first.id)
        : [];
    let datasets = [];
    var d = new Date();
    d.setDate(d.getDate() - 90);
    if (false && baaPerformanceStartDate && Date.parse(baaPerformanceStartDate) > d) {
      let startIndex = withValues.findIndex((data) => Date.parse(data.id) >= Date.parse(baaPerformanceStartDate));
      const guessValues = withValues
        .filter((data) => Date.parse(data.id) <= Date.parse(baaPerformanceStartDate))
        .map((data) => data.percent_change)
        .map((curr, i, array) => {
          return (array[i] += array[i - 1] ? array[i - 1] : 0);
        })
        .map((val) => val.toFixed(2));
      datasets = [
        {
          label: 'Portfolio (Estimate)**',
          usePercent: true,
          backgroundColor: 'rgba(21, 120, 246, 0.2)',
          borderColor: 'rgba(21, 120, 246, 0.5)',
          data: guessValues
        }
      ];
    } else {
      datasets = [
        {
          label: 'Portfolio',
          usePercent: true,
          backgroundColor: 'rgba(21, 120, 246, 0.2)',
          borderColor: 'rgb(21, 120, 246)',
          data: withValues
            .map((data) => data.percent_change)
            .map((curr, i, array) => {
              return (array[i] += array[i - 1] ? array[i - 1] : 0);
            })
            .map((val) => val.toFixed(2))
        }
      ];
    }
    datasets.push({
      label: baaBenchmarkName,
      usePercent: true,
      backgroundColor: 'rgba(21, 120, 246, 0.2)',
      borderColor: 'rgb(200, 120, 246)',
      data: benchValues
        .map((data) => data.percent_change)
        .map((curr, i, array) => {
          return (array[i] += array[i - 1] ? array[i - 1] : 0);
        })
        .map((val) => val.toFixed(2))
    });
    return {
      labels: withValues.map((data) => moment(data.id, 'YYYY-MM-DD').toDate()),
      datasets: datasets
    };
  }, [investorPerformanceData, baaBenchmarkPerformanceData, baaBenchmarkName, baaPerformanceStartDate]);

  const portfolioBenchmarkPerformanceData = useMemo(() => {
    const withValues = baaBenchmarkPerformanceData
      ? baaBenchmarkPerformanceData.filter((data) => data.percent_change != null)
      : [];
    return {
      labels: withValues.map((data) => moment(data.id, 'YYYY-MM-DD').toDate()),
      datasets: [
        {
          label: 'Benchmark',
          usePercent: true,
          backgroundColor: 'rgba(21, 120, 246, 0.2)',
          borderColor: 'rgb(21, 120, 246)',
          data: withValues
            .map((data) => data.percent_change)
            .map((curr, i, array) => {
              return (array[i] += array[i - 1] ? array[i - 1] : 0);
            })
            .map((val) => val.toFixed(2))
        }
      ]
    };
  }, [baaBenchmarkPerformanceData]);

  const getPerformanceData = async (ID, oneYearAgo, today, acctIds) => {
    if (!acctIds) return false; // TODO: find accounts by integration type, possibly handle race condition?

    const timeseriesPerformanceResponse = await fetch(
      `${url}/api/v1/account_timeseries.json?filter[date][gte]=${oneYearAgo}&filter[date][lte]=${today}&filter[account_id][eq]=${acctIds.join(
        ','
      )}&extra_fields[account_timeseries]=percent_change`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      }
    );

    return await timeseriesPerformanceResponse.json();
  };

  const getAdvisorScoreData = async (ID, oneYearAgo, today) => {
    const timeseriesScoreResponse = await fetch(
      `${url}/api/v1/investor_timeseries.json?filter[date][gte]=${oneYearAgo}&filter[date][lte]=${today}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      }
    );

    return await timeseriesScoreResponse.json();
  };

  const getAAData = useCallback(
    async (acctIds) => {
      const today = moment().format('YYYY-MM-DD');
      const oneYearAgo = moment().subtract(1, 'years').format('YYYY-MM-DD');

      const ID = await currentUser.getIdToken();

      const [baaData, investorData, performanceData, investorTS] = await Promise.all([
        getBaaInvestorData(ID),
        getInvestorData(ID),
        getPerformanceData(ID, oneYearAgo, today, acctIds),
        getAdvisorScoreData(ID, oneYearAgo, today)
      ]);

      const benchmarkId = investorData?.stats_breakdown?.benchmark_asset_id;
      if (performanceData) {
        // false if no acctIds
        const first = performanceData.data?.filter((data) => data.percent_change != null)[0];
        const benchmarkData =
          first && benchmarkId ? await getAaUserBenchmark(ID, benchmarkId, first.id, today) : { data: [] };
        setBaaBenchmarkPerformanceData(benchmarkData.data);
        setInvestorPerformanceData(performanceData.data);
      }
      setBaaBenchmarkName('Morningstar Benchmark*');
      setBaaPerformanceStartDate(investorData?.performance_start_date);

      setBaaUserData(baaData || {});
      setInvestorUserData(investorData || {});
      setInvestorTimeseriesData(investorTS.data);
    },
    [currentUser]
  );

  const updateBaaAccounts = useCallback(async () => {
    const ID = await currentUser.getIdToken();
    const res = await fetch(`${url}/api/v1/baa`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      }
    }).catch((err) => {
      console.log('Failed to update baa accounts: ', err);
      return;
    });
    if (res.status === 202) {
      console.log('Aggregation in progress');
    }
    return res;
  }, [currentUser]);

  const updateInvestor = useCallback(
    async (investorId, attributes) => {
      const ID = await currentUser.getIdToken();
      const res = await fetch(`${url}/api/v1/investors/${investorId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        },
        body: JSON.stringify({
          data: {
            type: 'investors',
            id: investorId,
            attributes
          }
        })
      }).catch((err) => {
        console.error('Failed to update investor: ', err);
        return;
      });
      getAAData();
      return res;
    },
    [currentUser, getAAData]
  );

  const createMeeting = useCallback(
    async (attributes) => {
      const ID = await currentUser.getIdToken();
      const res = await fetch(`${url}/api/v1/meetings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        },
        body: JSON.stringify({
          data: {
            type: 'meetings',
            attributes
          }
        })
      }).catch((err) => {
        console.error('Failed to create meeting: ', err);
        return;
      });
      getAAData();
      return res;
    },
    [currentUser, getAAData]
  );

  function sortAccounts(pageId, positionAccountIds) {
    if (pageId === 'liquid') {
      setLiqAccounts(LiqAccounts.sort(sortAccountsFn(positionAccountIds)));
    }
    if (pageId === 'personal') {
      setPersAccounts(PersAccounts.sort(sortAccountsFn(positionAccountIds)));
    }
    if (pageId === 'alternative') {
      setAltAccounts(AltAccounts.sort(sortAccountsFn(positionAccountIds)));
    }
    if (pageId === 'expense') {
      setExpenseAccounts(ExpenseAccounts.sort(sortAccountsFn(positionAccountIds)));
    }
    if (pageId === 'liabilities') {
      setLiabilityAccounts(LiabilityAccounts.sort(sortAccountsFn(positionAccountIds)));
    }
  }

  const reloadDom = useCallback(() => {
    setReload((reload) => !reload);
  }, []);

  const setSelectedMonth = (selected) => {
    getCategories();
    getCategoryTransactions(selected.startStr, selected.endStr);
    privSetSelectedMonth(selected);
  };

  const sortOtherLast = function (a, b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase

    if (nameA.includes('OTHER')) {
      return 1;
    } else if (nameB.includes('OTHER')) {
      return -1;
    }

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  async function getCategories() {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/categories.json?page[size]=200&include=parent,children`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      }
    });
    const json = await response.json();
    const categories = await json['data'];
    setCategoryData(categories);
  }

  const getAaUserBenchmark = async (ID, benchmarkId, oneYearAgo, today) => {
    const userResponse = await fetch(
      `${url}/api/v1/asset_timeseries.json?filter[date][gte]=${oneYearAgo}&filter[date][lte]=${today}&filter[asset_id][eq]=${benchmarkId}&system=1&extra_fields[asset_timeseries]=percent_change`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      }
    );

    return await userResponse.json();
  };

  useEffect(() => {
    if (!BaaAccounts.length) return;

    const acctIds = BaaAccounts.map((acct) => acct.id);

    if (acctIds.length && !onboarding) {
      getAAData(acctIds);
      setUsingBaa(true);
    }
  }, [BaaAccounts, getAAData, onboarding]);

  useEffect(() => {
    const removeCustoms = CategoryData.filter((cat) => !cat.custom_subtype);
    const alphabetized = removeCustoms.sort((a, b) => (a.name < b.name ? 1 : -1));
    const sortedCats = alphabetized.sort(sortOtherLast);

    const pushChildren = (cat) => {
      let excludedCats = [
        'Add New Account button',
        'Asset Account Tiles',
        'Transfer of Funds - Return of Capital',
        'Investment & Savings Contributions'
      ];
      if (!excludedCats.includes(cat.name) && cat.children && cat.children.length > 0) {
        nestedCats.push(cat);
        cat.children.sort(sortOtherLast).map((child) => nestedCats.push(child));
      } else if (
        !excludedCats.includes(cat.name) &&
        (!cat.children || cat.children.length === 0) &&
        cat.parent == null
      ) {
        nestedCats.push(cat);
      }
    };

    let nestedCats = ['Income'];

    sortedCats.filter((s) => s.category_type === 'income').forEach((cat) => pushChildren(cat));
    sortedCats.filter((s) => s.category_type === 'expense').forEach((cat) => pushChildren(cat));

    let idx = 1;
    let currentCats = nestedCats.map((cat, index) => {
      let catname = <div>&nbsp;&nbsp;&nbsp;&nbsp;{cat.name}</div>;
      let header = <div style={{ color: 'black', fontWeight: 800 }}>{cat}</div>;
      if (index !== 0 && nestedCats[index - 1].category_type === 'income' && cat.category_type === 'expense') {
        idx = index;
      }
      return {
        label: typeof cat === 'string' ? header : cat.parent == null ? cat.name : catname,
        value: cat.id,
        name: cat.name,
        disabled: typeof cat === 'string' ? true : false
      };
    });

    currentCats.splice(idx, 0, {
      label: <div style={{ color: 'black', fontWeight: 800 }}>Expense</div>,
      value: 0,
      disabled: true
    });

    const generateAltAccountItem = (account, subtype) => {
      const typeToName = {
        investment: 'Investment Income',
        return: 'Return of Capital',
        call: 'Capital Call'
      };

      return {
        label: (
          <div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {typeToName[subtype]}
          </div>
        ),
        name: account.name + ': ' + typeToName[subtype],
        value: account.id + subtype,
        props: {
          account: account.id,
          isCustom: true,
          customSubtype: subtype,
          existingId: CategoryData.find((cat) => cat.account_id === account.id && cat.custom_subtype === subtype)?.id
        },
        disabled: false
      };
    };

    const altAccountCategories = AltAccounts.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
    ).map((account) => [
      {
        name: account.name,
        value: 0,
        altAccountLabel: account.id,
        disabled: true
      },
      generateAltAccountItem(account, 'investment'),
      generateAltAccountItem(account, 'return'),
      generateAltAccountItem(account, 'call')
    ]);

    setCategoryDropdownData([
      {
        label: <div style={{ color: 'black', fontWeight: 800 }}>Alternative Accounts</div>,
        value: 0,
        disabled: true
      },
      ...altAccountCategories.flat(1),
      ...currentCats
    ]);
  }, [AltAccounts, CategoryData]);

  async function getCategoryTransactions(startStr, endStr) {
    const ID = await currentUser.getIdToken();
    const response = await fetch(`${url}/api/v1/categories/totals?startDate=${startStr}&endDate=${endStr}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ID}`
      }
    });

    const json = await response.json();
    setCategoryTotals(json);
  }

  const reduceAccountsArray = (accounts) => {
    return accounts.reduce((a, b) => a + b.day_change, 0);
  };

  useEffect(() => {
    //setLoading(true);
    async function getData() {
      const ID = await currentUser.getIdToken();
      let response = await fetch(`${url}/api/v1/account_positions.json`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      });
      let json = await response.json();
      const allPositions = json['data'];

      response = await fetch(`${url}/api/v1/accounts.json?page[size]=200&include=institution,assets`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`,
          'TZ-Offset': new Date().getTimezoneOffset()
        }
      });
      json = await response.json();

      setAllAccounts(json['data'].map((acct) => acct));

      const baaAccounts = json['data'].filter((acct) => acct.institution?.is_advisor_managed);
      setBaaAccounts(baaAccounts);

      let positions = allPositions.find((p) => p.page === 'liquid');
      let accounts = json['data'].filter((acct) => isLiquid(acct));
      let acctIds = accounts.map((acct) => acct.id);
      let excluded = accounts.filter((acct) => shouldExcludeAcct(acct));
      let included = accounts.filter((acct) => !shouldExcludeAcct(acct));
      let sortedByPos = included.slice(0).sort(sortAccountsFn(positions ? positions.account_ids : []));
      let sortedByBal = included.sort((a, b) => parseFloat(b.balance_current) - parseFloat(a.balance_current));
      setLiqData(formatAccounts(sortedByBal, excluded, 'Liquid'));
      setLiqAccounts(sortedByPos);
      setLiqIds(acctIds);
      setLiqChange({
        value: reduceAccountsArray(accounts),
        range: 'day'
      });

      positions = allPositions.find((p) => p.page === 'personal');
      accounts = json['data'].filter((acct) => isPersonal(acct));
      acctIds = accounts.map((acct) => acct.id);
      excluded = accounts.filter((acct) => shouldExcludeAcct(acct));
      included = accounts.filter((acct) => !shouldExcludeAcct(acct));
      sortedByPos = included.slice(0).sort(sortAccountsFn(positions ? positions.account_ids : []));
      sortedByBal = included.sort((a, b) => parseFloat(b.balance_current) - parseFloat(a.balance_current));
      setPersData(formatAccounts(sortedByBal, excluded, 'Personal'));
      setPersAccounts(sortedByPos);
      setPersIds(acctIds);
      setPersChange({
        value: reduceAccountsArray(accounts),
        range: 'day'
      });

      positions = allPositions.find((p) => p.page === 'alternative');
      accounts = json['data'].filter((acct) => isAlternative(acct));
      acctIds = accounts.map((acct) => acct.id);
      excluded = accounts.filter((acct) => shouldExcludeAcct(acct));
      included = accounts.filter((acct) => !shouldExcludeAcct(acct));
      sortedByPos = included.slice(0).sort(sortAccountsFn(positions ? positions.account_ids : []));
      sortedByBal = included.sort((a, b) => parseFloat(b.balance_current) - parseFloat(a.balance_current));
      setAltData(formatAccounts(sortedByBal, excluded, 'Alternative'));
      setAltAccounts(sortedByPos);
      setAltIds(acctIds);
      setAltChange({
        value: reduceAccountsArray(accounts),
        range: 'day'
      });

      positions = allPositions.find((p) => p.page === 'expense');
      accounts = json['data'].filter((acct) => isExpense(acct));
      accounts = accounts.sort(sortAccountsFn(positions ? positions.account_ids : []));
      included = accounts.filter((acct) => !shouldExcludeAcct(acct));
      setExpenseAccounts(included);

      positions = allPositions.find((p) => p.page === 'liabilities');
      accounts = json['data'].filter((acct) => isLiability(acct));
      acctIds = accounts.map((acct) => acct.id);
      accounts = accounts.sort(sortAccountsFn(positions ? positions.account_ids : []));
      excluded = accounts.filter((acct) => shouldExcludeAcct(acct));
      included = accounts.filter((acct) => !shouldExcludeAcct(acct));
      sortedByPos = included.slice(0).sort(sortAccountsFn(positions ? positions.account_ids : []));
      sortedByBal = included.sort((a, b) => parseFloat(b.balance_current) - parseFloat(a.balance_current));
      setLiabilityData(formatAccounts(sortedByBal, excluded, 'Liabilities'));
      setLiabilityAccounts(included);
      setLiabilityIds(acctIds);
      setLiabilityChange({
        value: reduceAccountsArray(accounts),
        range: 'day'
      });

      let allIds = json['data'].map((acct) => acct.id);
      setAllIds(allIds);

      let liabilitiesTotal = 0;
      json['data'].forEach((account) => {
        if (liabilities.includes(account.account_type) && !account.is_hidden) {
          liabilitiesTotal += account.balance_current;
        }
      });
      setLiabilitiesData(liabilitiesTotal);

      const baaData = await getBaaInvestorData(ID);
      setBaaUserData(baaData || {});
      const investorData = await getInvestorData(ID);
      setInvestorUserData(investorData || {});

      if (
        initialLoading &&
        investorData &&
        (investorData.statement_in_progress || baaAccounts.length > 0 || baaData?.in_progress_at)
      ) {
        //have accounts so waiting on scores
        var checkFor = [];
        if (!investorData.score_performance) {
          checkFor.push('score_performance');
        }
        if (!investorData.score_fees) {
          checkFor.push('score_fees');
        }
        if (!investorData.score_cash) {
          checkFor.push('score_cash');
        }
        setScoreSubmitted((scoreSubmitted) => [...scoreSubmitted, ...checkFor]);
      }

      //setLoading(false);
      setInitialLoading(false);
    }

    async function getInstitutionData() {
      const ID = await currentUser.getIdToken();
      const response = await fetch(`${url}/api/v1/institutions.json?page[size]=200&filter[is_featured][eq]=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      });
      const json = await response.json();
      setAllFeaturedInstitutions(json['data']);
    }

    async function getSelInstitutionData() {
      const ID = await currentUser.getIdToken();
      const response = await fetch(`${url}/api/v1/institutions.json?page[size]=200&filter[is_selectable][eq]=true`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      });
      const json = await response.json();
      setSelectableInstitutions(
        json['data'].map((fullAcct) => {
          return { id: fullAcct.id, name: fullAcct.name };
        })
      );
    }

    async function getInsights() {
      const ID = await currentUser.getIdToken();
      const response = await fetch(`${url}/api/v1/insights.json?include=tags`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      });
      const json = await response.json();
      setInsightsData(json['data']);
    }

    async function getTags() {
      const ID = await currentUser.getIdToken();
      const response = await fetch(`${url}/api/v1/tags.json?page[size]=200`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ID}`
        }
      });
      const json = await response.json();
      setTagsData(json['data']);
    }

    if (currentUser) {
      getData(); //not waiting, sets loading when done
      getInstitutionData();
      getSelInstitutionData();
      getCategories();
      getCategoryTransactions(selectedMonth.startStr, selectedMonth.endStr);
      getInsights();
      getTags();
    }
  }, [currentUser, reload]);

  const checkForScore = async () => {
    if (scoreSubmitted.length > 0) {
      const ID = await currentUser.getIdToken();
      const data = await getInvestorData(ID);
      let found = false;
      let reloadAccounts = false;
      for (let i = scoreSubmitted.length - 1; i >= 0; i--) {
        if (data && data[scoreSubmitted[i]] && data[scoreSubmitted[i]] !== 0) {
          found = true;
          if (['score_fees', 'score_cash'].indexOf(scoreSubmitted[i]) > -1) {
            reloadAccounts = true;
          }
          scoreSubmitted.splice(i, 1);
        }
      }
      if (found) {
        if (reloadAccounts) {
          // load new accounts which will also trigger getAAData
          reloadDom();
        } else {
          getAAData();
        }
      }
    }
    if (scoreSubmitted.length === 0) {
      setCheckInterval(null);
    }
  };
  useEffect(() => {
    if (scoreSubmitted.length > 0) {
      setCheckInterval(5000);
    }
  }, [scoreSubmitted, setCheckInterval]);
  useInterval(checkForScore, checkInterval);

  useEffect(() => {
    if (!doneOnboarding) {
      // onboarding if no accounts or no typeform
      setOnboarding(
        (AllAccounts.length === 0 &&
          !baaUserData?.baa_investor_credentials?.length &&
          !baaUserData?.in_progress_at &&
          !investorUserData?.statement_in_progress) ||
          !investorUserData?.provided_location_preference
      );
    }
  }, [doneOnboarding, AllAccounts, investorUserData, baaUserData]);

  const value = {
    onboarding,
    setOnboarding,
    doneOnboarding,
    setDoneOnboarding,

    usingBaa,
    setUsingBaa,
    baaUserData,
    investorUserData,
    investorTimeseriesData,
    advisorScoreChartData,
    feesTimeseriesData,
    investorPerformanceData,
    portfolioPerformanceData,
    portfolioBenchmarkPerformanceData,
    scoreSubmitted,
    setScoreSubmitted,

    BaaAccounts,
    LiqData,
    LiqAccounts,
    LiqIds,
    PersData,
    PersAccounts,
    PersIds,
    AltData,
    AltAccounts,
    AltIds,
    AllAccounts,
    ExpenseAccounts,
    LiabilityAccounts,
    LiabilityData,
    LiabilityIds,
    LiabilitiesData,
    CategoryData,
    CategoryTotals,
    CategoryDropdownData,
    AllFeaturedInstitutions,
    selectableInstitutions,
    InsightsData,
    TagsData,
    sortAccounts,
    reloadDom,
    getAAData,
    updateBaaAccounts,
    updateInvestor,
    createMeeting,
    initialLoading,
    selectedMonth,
    setSelectedMonth,
    AllIds,
    sortOtherLast,

    liqChange,
    setLiqChange,
    persChange,
    setPersChange,
    altChange,
    setAltChange,
    liabilityChange,
    setLiabilityChange
  };

  return (
    <AccountsContext.Provider value={value}>
      {(!initialLoading || !currentUser) && children}
      <Backdrop open={initialLoading} styles={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </AccountsContext.Provider>
  );
}
