import React, { useRef, useState } from 'react';
import { AiFillExclamationCircle, AiOutlineArrowRight } from 'react-icons/ai';
import { FaExclamationTriangle, FaSearchDollar } from 'react-icons/fa';
import { IoIosStar } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';

import {
  AdvisorNamePopup,
  CollapsibleText,
  LinkStep,
  MorningstarConnect,
  NewAccountSteps,
  ProcessingMessage
} from 'components';
import { Step } from 'components/cards/NewAccountSteps';
import { useAccountsData, useAuth } from 'contexts';

import { advisorText, feeText, performanceText } from './InsightsText';
import useStyles from './LandingStyles';

export const LandingAdvisor = () => {
  const classes = useStyles();
  const history = useHistory();
  const mstarRef = useRef<HTMLInputElement>(null);
  const [expandedFee, setExpandedFee] = useState('aum');

  const {
    baaUserData: { baa_investor_credentials },
    investorUserData: {
      advisor,
      advisor_searched_at,
      issue_types,
      score_growth,
      score_performance,
      score_cash,
      score_fees,
      score_compliance,
      statement_in_progress,
      stats_breakdown
    },
    BaaAccounts,
    scoreSubmitted
  } = useAccountsData();

  const { noAdvisor, currentUser } = useAuth();

  const setupComplete = noAdvisor
    ? BaaAccounts.length > 0 && score_performance
    : BaaAccounts.length > 0 && score_performance && advisor;
  const issueTypes: [string] = issue_types || [];
  const isDataIssue = issueTypes.includes('data');
  const feesPending = !score_fees && (BaaAccounts.length > 0 || scoreSubmitted.includes('score_fees'));

  const [advisorNameDialogOpen, setAdvisorNameDialogOpen] = useState(false);
  const onAdvisorNameDialogClose = () => {
    setAdvisorNameDialogOpen(false);
  };
  const openAdvisorNameDialog = () => {
    setAdvisorNameDialogOpen(true);
  };
  const connectStep = baa_investor_credentials?.length
    ? {
        title: 'Connect Account or Upload Statements',
        isComplete: score_fees && score_cash,
        actionText: 'Fix Connection Issues',
        score_attribute: 'score_fees',
        action: () => mstarRef.current?.click()
      }
    : {
        title: 'Connect Account or Upload Statements',
        isComplete: score_fees && score_cash,
        isPending: BaaAccounts.length > 0,
        actionText: 'Connect or Upload',
        score_attribute: 'score_fees',
        action: () => history.push('/advisor-analyzer/add-account')
      };

  const fee_potential = stats_breakdown?.fee_potential;
  const total_fee_percent = stats_breakdown?.total_fee_percent;
  const total_fees = stats_breakdown?.total_fees;
  const feeInsightsIcon = () => {
    if (
      score_fees < 90 ||
      (issueTypes.length && issueTypes.includes('aum_fees_higher')) ||
      performanceInsights.length > 0
    ) {
      return <AiFillExclamationCircle color="#EEE" size={50} />;
    }
    return <IoIosStar color="rgb(21, 120, 246)" size={38} />;
  };
  const feesInsights = () => {
    let insights = [];
    if (issueTypes.length && issueTypes.includes('aum_fees_higher')) insights.push(feeText.aum_fees_higher);
    if (score_fees >= 90) {
      if (insights.length > 0) return insights;
      return [feeText.good];
    }
    if (score_fees < 90) {
      insights.push(feeText.high_fee_amount(total_fee_percent, total_fees));
    }
    if (score_fees < 80) {
      if (fee_potential && Math.floor(fee_potential / 500) * 500) {
        insights.push(feeText.potential(fee_potential));
      } else {
        insights.push(feeText.high_fees);
      }
    } else if (score_fees < 90) {
      insights.push(feeText.medium_fees);
    }
    return insights;
  };
  const performanceInsights = () => {
    let insights = [];
    if (score_growth && score_growth < 80) {
      insights.push(performanceText.low_growth);
    }
    if (score_cash && score_cash < 80) {
      insights.push(performanceText.cash_allocation);
    }
    if (stats_breakdown && stats_breakdown.allocation_percentages.cash > 5) {
      const good_cash_percent = (stats_breakdown.cash.good / stats_breakdown.cash.total) * 100;
      if (good_cash_percent < 95) {
        const bad_cash_percent =
          ((stats_breakdown.cash.total - stats_breakdown.cash.good) / stats_breakdown.cash.total) * 100;
        insights.push(performanceText.bad_cash(stats_breakdown.allocation_values.cash, bad_cash_percent));
      }
    }
    return insights;
  };
  const credentialInsights = () => {
    let insights: string[] = [];
    if (!advisor) {
      return [];
    }
    if (advisor.is_ia && advisor.is_broker) {
      insights.push(advisorText.dually_registered(advisor.first_name));
    }
    if (!advisor.is_ia) {
      if (advisor.is_broker) {
        insights.push(advisorText.broker_only);
      } else {
        insights.push(advisorText.not_registered);
      }
    }
    if (advisor.num_disclosures > 0) {
      insights.push(advisorText.has_disclosures(advisor.first_name, advisor.last_name));
    }
    if (advisor.is_cfp === false && advisor.is_cfa === false && advisor.is_cpa === false) {
      insights.push(advisorText.no_credentials(advisor.first_name));
    }
    if (insights.length > 0) return insights;
    return ['No apparent issues to report.', 'We will continue to monitor.'];
  };

  let advisorSteps: [Step] = [
    {
      title: 'Analyze my Advisor',
      isComplete: score_compliance,
      actionText: 'Complete Now',
      action: 'BmBBBQyn',
      score_attribute: 'advisor_searched_at'
    }
  ];

  let advisorErrorMessage: string | undefined | JSX.Element = undefined;
  if (!advisor) {
    if (advisor_searched_at) {
      advisorSteps = [
        {
          title: 'Advisor not found',
          isComplete: !!advisor,
          actionText: 'Try Again',
          action: openAdvisorNameDialog,
          score_attribute: 'advisor_searched_at'
        }
      ];
    } else if (scoreSubmitted.includes('advisor_searched_at')) {
      advisorErrorMessage = (
        <div className={classes.row}>
          <ProcessingMessage />
        </div>
      );
    }
  } else if (!score_compliance) {
    advisorErrorMessage = 'Calculating... check back soon.';
  }

  const firstName = currentUser?.displayName ? currentUser.displayName.split(' ')[0] : undefined;

  return (
    <>
      <h2 className={classes.title}>Welcome to Rainbook{firstName ? `, ${firstName}` : ''}!</h2>
      <div className={classes.section}>
        <div className={classes.header}>
          <h2 className={classes.title}>
            <FaExclamationTriangle style={{ verticalAlign: 'middle' }} />{' '}
            {advisor
              ? `Red flag analysis of your advisor ${advisor.first_name}:`
              : 'Does your advisor have any red flags?'}
          </h2>
        </div>
        {!advisor && (
          <div className={classes.row}>
            <CollapsibleText
              title={
                <div className={classes.collapsibleTitle}>
                  <b>Conflict of interest?</b>
                </div>
              }
              text={
                <ul>
                  <li>88% of financial advisers are registered brokers and have conflicts.</li>
                  <li>
                    Only independent investment advisors are legally bound to the fiduciary standard ALL the time. They
                    are not allowed to receive commissions.
                  </li>
                </ul>
              }
            />
            <CollapsibleText
              title={
                <div className={classes.collapsibleTitle}>
                  <b>Lacking Credentials?</b>
                </div>
              }
              text={
                <ul>
                  <li>Look for the CFP®, CFA or CPA.</li>
                  <li>Certified Financial Planners focus on long term holistic financial planning.</li>
                </ul>
              }
            />
            <CollapsibleText
              title={
                <div className={classes.collapsibleTitle}>
                  <b>Disclosures or Complaints?</b>
                </div>
              }
              text={
                <ul>
                  <li>Disclosures on an advisor's record can be indicative of a problematic history.</li>
                </ul>
              }
            />
            <div>
              Answer a few questions to find out if <i>your</i> advisor is a fiduciary and if they have any red flags.
            </div>
          </div>
        )}
        {advisorErrorMessage ? (
          advisorErrorMessage
        ) : advisor ? (
          <div className={score_compliance < 90 ? classes.rowAlert : classes.rowRow}>
            {score_compliance < 90 ? (
              <AiFillExclamationCircle color="#EEE" size={50} />
            ) : (
              <IoIosStar color="rgb(21, 120, 246)" size={38} />
            )}
            <ul className={classes.standardList}>
              {credentialInsights().map((i) => (
                <li>{i}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div className={classes.row}>
            <NewAccountSteps steps={advisorSteps} />
            <AdvisorNamePopup open={advisorNameDialogOpen} onClose={onAdvisorNameDialogClose} />
          </div>
        )}
        {score_compliance && (
          <div className={classes.match}>
            <LinkStep
              title="Receive a free portfolio and fee review"
              actionText="Schedule Now"
              to="/advisor-analyzer/advisor-match"
            />
          </div>
        )}
      </div>
      <div className={classes.section}>
        <div className={classes.header}>
          <h2 className={classes.title}>
            <FaSearchDollar />
            {score_fees ? `Your fee and portfolio analysis:` : 'Are you paying too much in fees?'}
          </h2>
        </div>
        {!score_fees && (
          <div className={classes.row}>
            <div>Fees you might be paying:</div>
            <div className={classes.tabbedArea}>
              <div className={classes.tabs}>
                <div
                  className={(expandedFee === 'aum' && classes.selectedTab) || ''}
                  onClick={() => setExpandedFee('aum')}
                >
                  <b>Management Fee</b>
                  <div>Should be 1% or less</div>
                </div>
                <div
                  className={(expandedFee === 'fund' && classes.selectedTab) || ''}
                  onClick={() => setExpandedFee('fund')}
                >
                  <b>Fund Fees</b>
                  <div>0.36% average</div>
                </div>
                <div
                  className={(expandedFee === 'load' && classes.selectedTab) || ''}
                  onClick={() => setExpandedFee('load')}
                >
                  <b>Commission Fees</b>
                  <div>None for a fiduciary</div>
                </div>
              </div>
              <div className={classes.tabbedBody}>
                {expandedFee === 'aum' ? (
                  <ul className={classes.standardList}>
                    <li>
                      Calculated based on a percentage of your assets under management, charged quarterly or monthly.
                    </li>
                    <li>Averages around 0.80% - 1.0%</li>
                  </ul>
                ) : expandedFee === 'fund' ? (
                  <ul className={classes.standardList}>
                    <li>Fees associated with the mutual funds or ETFs held in your account, charged indirectly.</li>
                    <li>Sometimes called layered fees or expense ratio fees.</li>
                    <li>Average fee paid for passive funds is 0.11% and across investor portfolios is 0.36%.</li>
                  </ul>
                ) : expandedFee === 'load' ? (
                  <ul className={classes.standardList}>
                    <li>Commission or sales fees charged on top of the annual fund fee.</li>
                    <li>
                      Including a 12b-1 fee which is used as a commission to a <b>broker</b> to sell you the fund.
                    </li>
                    <li>Front Load fees are charged up front when you buy certain mutual funds.</li>
                    <li>
                      Note: Fiduciary Advisors do not charge load fees as they represent a substantial conflict of
                      interest. Average fee range is an eye popping 3.75% - 5.75%.
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div>
              To see how <i>your</i> fees compare, connect to analyze your advisor managed account:
            </div>
          </div>
        )}
        {statement_in_progress || isDataIssue || feesPending ? (
          <div className={classes.row}>
            <ProcessingMessage {...{ statement_in_progress, isDataIssue }} />
          </div>
        ) : score_fees ? (
          <div className={score_fees < 90 ? classes.rowAlert : classes.rowRow}>
            {feeInsightsIcon()}
            <ul className={classes.standardList}>
              {feesInsights().map((i) => (
                <li>{i}</li>
              ))}
              {performanceInsights().map((i) => (
                <li>{i}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div className={classes.row}>
            <NewAccountSteps steps={[connectStep]} />
            <MorningstarConnect refForClick={mstarRef} />
          </div>
        )}
        {score_fees && (
          <div className={classes.match}>
            <LinkStep
              title="Receive a free portfolio and fee review"
              actionText="Schedule Now"
              to="/advisor-analyzer/advisor-match"
            />
          </div>
        )}
      </div>
      {setupComplete && (
        <div style={{ margin: '2rem' }}>
          <Link className={classes.matchLink} to="/advisor-analyzer">
            Explore your detailed RAIN® report.
            <AiOutlineArrowRight size={16} />
          </Link>
        </div>
      )}
    </>
  );
};
